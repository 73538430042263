import { useEffect, useState } from "react"
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer"
import download from "../assets/reports/download.webp"
import { isMobile } from "react-device-detect"

const ExecutorReport = (props) => {
  const [report, setReport] = useState()

  useEffect(() => {
    if (!props.report) return
    setReport(props.report)
  }, [props.report])

  return (
    <div
      style={props.show ? { display: "block" } : { display: "none" }}
      className="reports-subpage-preview-container executor"
    >
      {isMobile ? null : <PDFViewer className="report-viewer">{report && report}</PDFViewer>}
      <div className="report-transparent-cover">
        <div className="report-description">
          Your Executor report is what your next of kin receives. We recommend you review this quarterly to make sure
          your next of kin can have peace of mind.
        </div>
        {report && report ? (
          <PDFDownloadLink document={report} fileName="executor_report.pdf" className="report-download-button link">
            <img src={download} alt="Download" />
            Download your Executor report
          </PDFDownloadLink>
        ) : null}
      </div>
    </div>
  )
}

export default ExecutorReport
